@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/css?family=Roboto");
body {
  margin: 0;
  font-family: "Roboto";
}

.App {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  background-color: #85a8c5;
}

.carousel {
  overflow: hidden;
  width: 700px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.inner {
  white-space: nowrap;
  transition: transform 0.3s;
}

.carousel-item {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 490px;
  background-color: #fff;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.05);
}

.carousel-item-title {
  font-size: 1.5rem;
  text-align: center;
  white-space: normal;
  color: #26343f;
  font-weight: bold;
}

.carousel-item-text {
  font-size: 1.15rem;
  margin: 40px 0 40px 0;
  padding: 0 20px 0 20px;
  white-space: normal;
  color: #617d98;
}
.carousel-img {
  width: 250px;
  padding: 0 20px 0 20px;
}

/* final stage */

.carousel-buttons {
  display: flex;
  justify-content: space-evenly;
}

.button-arrow {
  background: none;
  border: none;
  cursor: pointer;
  margin-top: 20px;
}

.indicators {
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-top: 20px;
}

.indicator-buttons {
  border: none;
  cursor: pointer;
}

.indicators > button {
  margin: 5px;
  background: none;
}

.indicator-symbol {
  color: #26343f;
}

.indicator-symbol-active {
  color: #ffffff;
}